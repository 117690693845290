import React from 'react'
import Link from 'gatsby-link'
import woman from '../../assets/images/woman.png'
import skull from '../../assets/images/skull.png'
import coverImg from '../../assets/images/cover-img-2.png'
import './style.css'

const DeathPrep = ({ back }) => (
  <div className='dp-component'>
     <div className="options-page-grid">
        <div className="problem-wrapper">
            <img className="dp-skull" src={skull} />
            <Link className="arrow-outer" to="/table-of-contents"><div className="option-arrow">&larr;</div></Link>
            <div className="problem-content">
                <div className="problem-label">Problem:</div>
                <p>
                Anxiety, fear, billions of dollars of end of life costs, and a strain on hospital resources have all coalesce creating a large drain on our society due to our unwillingness to accept and prepare for death. This has resulted in undue stress on ourselves and our loved ones who have to make untenable decisions with regards to our life as well as have resulted in us spending exorbitant amounts of money on funeral service costs. It is a massive black hole on our society. With baby boomers nearing end of life, and with new conversations around death because of Covid-19 now is the time to act. 
                </p>
            </div>
            <h1 className="dp-title">Death Preparation.</h1>
        </div>
        <div className="solution-wrapper">
        
        {/* <div className="option-arrow option-right"  onClick={() => back('funeral-costs')}>&rarr;</div> */}
            <div className="solution-label">Solution:</div>
            <p>
            Education and Normaliztion. Fearing the most the one thing in the world that we know is inevitable is a heavy burden for anyone to carry. We need to at the very least normalize death. Educating people about what they want when they die, how they want to die, and what they want done if they are brought to the E.R. are all small steps we can take that will help us break out of this viscious cycle. Death may always be dark, but it doesn't have to be taboo. There is a great death positivity movement happening in this country, but we need to make it mainstream.
            </p>
            <h3>Actions: National Death Education & Reformation Campaign</h3>
            <ol className="death-action-ul">
                <li>Get Local. To fight the funeral industry we need community death spaces - the death coop as funeral home. Set up grants and funds to encourage people and communities to create shared death spaces that are inexpensive and create death services aligned to the needs of the community.</li>
                <li>Create art and stories that stop trying to hide death from kids (and adults). We should have never white washed our fairly tales.</li>
                <li>Create a national death registry that has whether or not you are DNR or full code along with what you want done with your organs and remains</li>
                <li>Find celeberities and influences who are willing to share how their experiences with death has shaped them as a person.</li>
                <li>Work directly with communities to change the laws that have allowed traditional funeral homes to monopolize death services.</li>
                <li>PSAs, social campaigns, Memes focused on removing some of the morbidity around death. Teach them about dying options, disposal options, creating wills, and ER code status. Put info on a license, etc</li>
                <li>Make a documentary about all things death. Think Netflix's half-animated special on psychedelics. It focused on misinformation, education, and mindset.</li>
            </ol>
       
            <Link to="/options/educate-death" className="campaign-link options-final-link"><h1 className="dp-title">Additional Resources Here!</h1></Link>
        </div>
     </div>
  </div>
)

export default DeathPrep