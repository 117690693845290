import React from 'react'
import Link from 'gatsby-link'
import './style.css'
import DeathPrep from '../../components/DeathPrep'
import FuneralCosts from '../../components/FuneralCosts'

// import Timeline from 'react-timeline-semantic-ui';

class Options extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
       option: 'death-prep'
    }
    this.toggleOption = this.toggleOption.bind(this);
  }

  toggleOption(clicked) {
    console.log(clicked);
    if (clicked == "death-prep") {
       this.setState({option:"death-prep"})
    }
    if (clicked == "funeral-costs") {
      this.setState({option:"funeral-costs"})
    }

    if (clicked == "intro") {
    this.setState({option:"intro"})
    }
  }

  render() { 
    return (
      <div className='options-page'>
      {this.state.option == 'intro' ?
      <div className="options-page-grid">
        <div className="options-left-side">
        <div className="option-intro">Our unwillingness to confront death and talk about it meaningful ways while we are alive has created a system in which we are willfully unprepared for the one unevitable future. In addition to costing tax payers billions of additional dollars in health care and creating undue trama on patients at the end of their lives, this unpreparedness has left open a massive opening that has been exploited by the funeral industry for over one hundred years. As technology pours into our everyday lives and connectivity and information because ubiquitous we have unique opportunties to correct two of the largest issues that death in our society has created.
        <div className="options-TOC"><Link to="/table-of-contents">&larr;Back to TOC</Link></div>
        </div>
        </div>
        <div className="option-wrapper-outer">
          <div className="option-wrapper">
            <h1 onClick={this.toggleOption.bind(this, 'death-prep')}>Death Preparation</h1>
            <div className="optionsAnd">&</div>
            <Link to="/word-game"><h1>Death Game/Meditation</h1></Link>
          </div>
        </div>
      </div> : null }
      {this.state.option == 'death-prep' ? 
      <DeathPrep back={this.toggleOption}/> : null}
       {this.state.option == 'funeral-costs' ? 
      <FuneralCosts back={this.toggleOption}/> : null}
      </div>
    )
  }

}

export default Options
