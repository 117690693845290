import React from 'react'
import Link from 'gatsby-link'
import circle from '../../assets/images/toc-condensed-circles.png'
import pathDots from '../../assets/images/cover-img-2.png'


const FuneralCosts = ({ back }) => (
  <div className='dp-component'>
  <img className="options-path" src={pathDots} />
  <div className="options-page-grid">
     <div className="problem-wrapper">
         <div className="option-arrow"  onClick={() => back('death-prep')}>&larr;</div>
         <div>
          <div className="problem-label">Problem:</div>
          <p>                
          Americans pay an average of $8,000 per funeral. There are bodies stacked up in inner city morgues because families can't afford funeral services. The death industry has had an 125 year reign of unchecked power in the death space where they have been allowed to freely feed on our lack of education during our times of grief. 
          </p>
         </div>
         <h1 className="dp-title">Funeral Costs are Insane.</h1>
     </div>
     <div className="solution-wrapper">
     <img className="dp-skull" src={circle} />
         <div>
         <div className="solution-label">Solution:</div>
         <div className="option-arrow option-right"  onClick={() => back('intro')}>&rarr;</div>
         <p>
          We need to encourage disruption in the funeral industry. Communities, non-profits, and entrepreneurs need to be shown the opportunities in fair trade death services (funeral homes,crematoriums, new methods of body disposal, etc). Additonally, we need to relax laws that will have severely hampered growth in the space.
         </p>
         </div>
         <div>
         <h3>Actions: Community Outreach, New Lobbies, Grants & Funds </h3>
         <ul className="death-action-ul">
             <li>Set up grants and funds to encourage people and communities to create shared death spaces that are inexpensive and create death services aligned to the needs of the community. </li>
             <li>Work directly with communities to change the laws that have allowed traditional funeral homes to monopolize death services.</li>
         </ul>
         </div>
         <Link to="options/educate-death" className="campaign-link options-final-link"><h1 className="dp-title">Let's Break the System!</h1></Link>
     </div>
  </div>
</div>
)

export default FuneralCosts